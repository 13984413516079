<template>
  <div class="warp">
    <van-sticky>
      <div>
        <van-search
          v-model="title"
          placeholder="请选择"
          @click="searchClick"
          readonly
        >
        </van-search>
      </div>
    </van-sticky>
    <van-popup v-model="searchShow" position="top" >
      <van-form @submit="onSubmit">
        <van-field
          v-model="RegionFrom.OrganName"
          name="OrganName"
          is-link
          readonly
          label="社区"
          placeholder="请选择社区"
          @click="showSq = true"
        />
        <van-popup v-model="showSq" round position="bottom">
          <van-cascader
            v-model="cascaderValue"
            title="请选择社区"
            :options="orgonList"
            @close="showSq = false"
            :field-names="{
              text: 'OName',
              value: 'OCode',
              children: 'Children',
            }"
            @finish="clickSq"
          />
        </van-popup>
        <van-field
          v-model="RegionFrom.gName"
          label="网格"
          name="gId"
          placeholder="请选择网格"
          @click="xiaoquListPicker = true"
          readonly="readonly"
          clickable
        />
        <van-popup v-model="xiaoquListPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="xiaoqu"
            value-key="Title"
            @confirm="xiaoquListQr"
            @cancel="xiaoquListPicker = false"
          />
        </van-popup>
        <van-field
          v-model="RegionFrom.name"
          name="name"
          label="姓名"
          placeholder="姓名"
        />
        <van-field
          v-model="RegionFrom.tel"
          name="tel"
          label="联系电话"
          placeholder="联系电话"
        />
        <div style="margin: 16px;display: flex;justify-content: space-between;">
          <van-button round block type="primary" @click="chongzhi" style="width:45%;"
            >重置</van-button
          >
          <van-button round block type="info" native-type="submit"  style="width:45%;"
            >搜索</van-button
          >
        </div>
      </van-form>
    </van-popup>

    <div class="wktime">
      <!-- 全部 -->
      <van-list
        :loading="loading"
        :finished="finished"
        offset="0"
        @load="onLoad"
        :immediate-check="false"
      >
        <div
          v-for="item in list"
          :key="item"
          class="listRid"
          style="display: flex"
        >
          <div style="width: 30%; text-align: center">
            <img :src="item.Headimg" alt="" style="width: 80%" @error="setDefaultImage"/>
          </div>
          <div style="width: 65%">
            <div class="listRidtitle">{{ item.OName }}-{{ item.GName }}</div>
            <div class="listRidname">
              <span>联系人: {{ item.Name }}</span>
              <!-- <span>联系电话: {{ item.Mobile }}</span> -->
            </div>
            <div class="listRidname">联系电话: {{ item.Mobile }}</div>
            <div class="listRidname">地址: {{ item.Address }}</div>
          </div>
        </div>
      </van-list>
    </div>
    <!-- <div style="text-align: center">
        <van-loading type="spinner" v-if="loadingShow" />
      </div> -->
    <van-empty description="暂未数据" v-if="!loadingShow" />
  </div>
</template>
  
  <script>
import { WxGetGridMedicinePage } from "@/api/index";
import { WeGetOrganTree, WeGetGridList } from "@/api/Organ";

export default {
  data() {
    return {
      title:'',
      RegionFrom: {
        title: undefined,
        page: 1,
        limit: 10,
      }, //小区列表传参
      list: [], //小区列表
      orgonList: [],
      xiaoqu: [],
      cascaderValue: "",
      loadingShow: false,
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      searchShow: false,
      showSq: false,
      xiaoquListPicker: false,
    };
  },
  created() {
    this.RegionPage();
    this.getOrgonTree();
  },
  methods: {
    setDefaultImage(e){
      e.target.src = 'https://lccgzapi.zhsq.cloud//upload/images/2022/9/d7367205-334.png'
    },
    // 重置表单
    chongzhi(){
      this.RegionFrom = {
        name: undefined,
        tel: undefined,
        gId: undefined,
        OrganName: undefined,
        oCode: undefined,
        page: 1,
        limit: 10,
      } //小区列表传参
    },
    // 社区组织
    getOrgonTree() {
      WeGetOrganTree().then((res) => {
        this.orgonList = res.data.data;
      });
    },
    clickSq({ selectedOptions }) {
      console.log(1);
      console.log(selectedOptions);
      this.showSq = false;
      this.RegionFrom = {
        page: 1,
        limit: 10,
        gId: "",
      };
      this.fieldValue = selectedOptions.map((option) => option.OName).join("/");
      this.OCode = selectedOptions.map((option) => option.OCode).join("/");
      this.RegionFrom.OrganName = this.fieldValue;
      this.RegionFrom.oCode = selectedOptions[1].OCode;
      this.getGridTree(this.RegionFrom.oCode);
    },
    getGridTree(row) {
      WeGetGridList({organCode:row}).then((res) => {
        this.xiaoqu = res.data.data;
      });
    },
    //网格列表确认
    xiaoquListQr(val) {
      console.log(val);
      this.RegionFrom.gId = val.GId;
      this.RegionFrom.gName = val.Title;
      this.xiaoquListPicker = false;
    },
    searchClick() {
      this.searchShow = true;
    },
    // 下拉刷新
    onLoad() {
      this.loading = true;
      this.RegionFrom.page = this.RegionFrom.page + 1;
      this.RegionPage();
    },
    // 搜索
    onSubmit(row) {
      console.log(row);
      console.log(this.RegionFrom);
      this.RegionFrom.page = 1;
      this.list = [];
      // this.RegionFrom.oCode = row.oCode;
      // this.RegionFrom.gId = row.gId;
      this.RegionFrom.name = row.name;
      this.RegionFrom.tel = row.tel;
      this.RegionPage();
      this.searchShow = false;
    },
    onClickLeft() {
      window.history.go(-1);
    },
    // 获取小区列表
    RegionPage() {
      // this.list = [];
      // this.loadingShow = true;
      WxGetGridMedicinePage(this.RegionFrom).then((res) => {
        let rows = res.data.data; //请求返回当页的列表
        // 加载状态结束
        this.loading = false;

        if (res.data.code == 0) {
          if (rows == null || rows.length === 0) {
            // 加载结束
            console.log("结束");
            this.finished = true;
            this.hidden = true;
            return;
          }
          // 将新数据与老数据进行合并
          this.list = this.list.concat(rows);

          //如果列表数据条数>=总条数，不再触发滚动加载
          if (this.list.length >= res.data.count) {
            this.finished = true;
            this.hidden = true;
          }
        }
      });
    },
  },
};
</script>
  
  <style scoped>
.homebanner img {
  width: 100%;
  vertical-align: top;
}
.wktime {
  padding: 0 20px;
}
.wktype {
  padding: 0 10px 10px;
}
.orginview .iconimg {
  width: 62%;
}
.orginview .linktext {
  font-size: 14px;
  margin-top: 10px;
}
.wktype .iconimg {
  width: 65%;
}
.wktype .wktext {
  margin-top: 10px;
  font-size: 15px;
}
.grouptitle {
  border-left: 4px solid #1989fa;
  text-indent: 15px;
  font-weight: normal;
  font-size: 15px;
  margin: 12px 10px;
}
.brakline {
  height: 5px;
  width: 100%;
  background: #eee;
  display: block;
  content: "";
}
.listRid {
  background: rgb(238, 236, 236);
  border-radius: 15px;
  box-shadow: 1px 1px 1px #cececc;
  margin: 8px 0px;
  padding: 8px;
  font-size: 14px;
}
.listRid .listRidtitle {
  border-bottom: 1px solid #999;
  padding-bottom: 8px;
  font-size: 16px;
}
.listRidname {
  padding-top: 8px;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
}
.listRidAddress {
  padding: 3px 0px;
}
</style>